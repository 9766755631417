/* Navigation Styling */
.navbar-logo {
  font-family: "Oswald", sans-serif;
  font-weight: lighter;
  color: black !important;
}

.navbar-nav .nav-link:hover {
  border-bottom: 2px solid #000000;
  padding: 6px 0;
}

@media (max-width: 991px) {
  .navbar .navbar-nav {
    display: inline-block;
    float: none;
    vertical-align: top;
  }

  .navbar .navbar-collapse {
    text-align: center;
  }
}

.navbar-css {
  background-color: white !important;
  border-bottom: 0.5px solid #000000;
}

.navbar-elements {
  font-weight: lighter;
  font-size: 15px;
  color: black !important;
}

/* Fluid Image Banner Styling */
.image-banner {
  filter: contrast(0.9);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.fluid-image-position {
  padding-top: 30px;
  padding-bottom: 30px;
}

/*Introduction Styling*/
.introduction-title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: lighter;
  text-align: center;
  letter-spacing: 2px;
}

.introduction-text {
  font-weight: lighter;
  text-align: center;
  padding-bottom: 20px;
  line-height: 30px;
}

.introduction-text-bottom {
  font-weight: lighter;
  text-align: center;
  padding-bottom: 20px;
  line-height: 30px;
}

@media (min-width: 991px) {
  .introduction-text {
    font-weight: lighter;
    text-align: center;
    margin-left: 150px;
    margin-right: 150px;
    line-height: 30px;
  }

  .introduction-text-bottom {
    font-weight: lighter;
    text-align: center;
    margin-left: 150px;
    margin-right: 150px;
    padding-bottom: 20px;
    line-height: 30px;
  }
}

.introduction-block {
  border: black solid 1px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.introduction-spacing {
  padding-top: 40px;
  padding-bottom: 60px;
}

/*Table Styling*/

.table-styling {
  border: black solid 1px;
  font-weight: lighter;
}

.table-styling-top {
  font-weight: lighter;
  font-size: 30px;
  text-align: center;
}

.table-styling-bottom {
  font-weight: lighter;
  font-size: 15px;
  text-align: center;
}

.table-outer-styling {
  padding-bottom: 60px;
}
/*Services & Contacts styling*/
.services-contacts-styling {
  padding-bottom: 60px;
}

.services-contacts-format {
  padding-bottom: 120px;
  padding-top: 40px;
  font-weight: lighter;
  font-style: italic;
}

.services-contacts-name {
  font-weight: bolder;
  font-style: normal !important;
}
/*Logo Styling*/
.logo-styling{
  padding-top:10px;
  width: 80px;
  height: 50px;
}

/*Footer Styling*/
.map-container-2 {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.map-container-2 iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100;
  position: absolute;
  border: 4px;
}
.footer-format {
  padding-bottom: 40px;
}

.footer-text {
  color: white;
  padding-top: 90px;
  padding-bottom: 90px;
  font-weight: lighter;
}

.footer-icon {
  align-content: right;
}

.footer-spacing {
  padding-top: 10px;
}

.footer-content {
  padding-top: 40px;
}

.copyright {
  color: White;
  text-align: center;
  font-size: 10px;
  font-weight: lighter;
}

a {
  color: white !important;
}

.footer-background-overall {
  background-color: black;
  padding-bottom: 30px;
  padding-top: 30px;
}
